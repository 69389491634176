const slugify = (text) => {
  return text
    .toString() // Convert to string
    .normalize("NFD") // Normalize to NFD (decompose combined characters)
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
    .toLowerCase() // Convert to lowercase
    .trim() // Trim whitespace from both sides
    .replace(/[^a-z0-9 -]/g, "") // Remove invalid characters
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/-+/g, "-"); // Replace multiple - with single -
};

module.exports = {
  slugify,
};
