import * as React from "react";
import { Link } from "gatsby";

import "./index.css";

import Logo from "../../images/logo-white.png";

const NavigationBar = () => {
  return (
    <div className="navigationbar">
      <div>
        <Link to="/">
          <div>
            <img src={Logo} style={{ height: 60 }} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NavigationBar;
