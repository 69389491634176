import React from "react";
import { Link } from "gatsby";
import "./index.css";
import formattedListData from "../../../data.json";

import { slugify } from "../../../helpers";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 My Website. All rights reserved.</p>

        <div className="footer-sections">
          <div className="footer-links">
            <Link to="/tothemax">ToTheMax</Link>
            <Link to="/tripadvisor-filter">Tripadvisor Restaurant Filter</Link>
          </div>

          <div className="footer-lists">
            {formattedListData.categories
              .flatMap((category) =>
                category.lists.map((list) => ({
                  ...list,
                  category: category.name, // assuming category has a 'name' property
                }))
              )
              .filter((list) => list.includeInFooter)
              .map((listData, index) => (
                <Link to={slugify(listData.name)}>
                  <div key={index} className="footer-list-item">
                    <h4>{listData.name}</h4>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
