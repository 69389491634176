import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import "./app-page.css";
import HideRestaurantScreenshot from "./screenshots/hide-restaurant.png";
import AutoHideChainRestaurantScreenshot from "./screenshots/auto-hide-chain-restaurants.png";

const TripAdvisorFilterPage = () => {
  const features = [
    {
      title: "Hide restaurants",
      description:
        "Using the Tripadvisor Filter Chrome extension is simple and intuitive. When you come across a restaurant you want to hide, just click on Hide button to open the pop-up dialog. Here, you can select a reason for hiding the restaurant and add any additional notes. This information is stored securely in the Chrome extension storage. The next time you visit Tripadvisor, the extension will automatically hide the restaurants you've filtered out, giving you a cleaner, more focused browsing experience without any extra effort.",
      image: HideRestaurantScreenshot,
    },
    {
      title: "Auto-hide chain restaurants",
      description:
        "With the Tripadvisor Filter Chrome extension, you can also enjoy the convenience of automatically hiding chain restaurants. We maintain a comprehensive list of popular chain restaurants, and as soon as you visit a Tripadvisor page, the extension will instantly hide these chains from your search results. This way, you can focus on discovering unique, local dining options without the clutter of familiar chain names. It's perfect for foodies looking to explore new culinary experiences and avoid the usual suspects.",
      image: AutoHideChainRestaurantScreenshot,
    },
  ];

  return (
    <main>
      <NavigationBar />
      <div className="hero-section">
        <div>
          <h1 id="website-title">Tripadvisor Restaurant Filter</h1>
          <p id="website-tagline">
            Chrome extension for hiding Tripadvisor restaurants
          </p>
        </div>
      </div>
      <div className="container">
        <div>
          Tired of sifting through endless restaurant listings on Tripadvisor?
          Meet the Tripadvisor Filter Chrome extension! This handy tool lets you
          hide the restaurants you're not interested in, so you can focus on the
          ones that really catch your eye. Whether you're planning a quick bite
          or a fancy dinner, our extension makes it super easy to find the
          perfect spot. Say goodbye to overwhelming searches and hello to a
          personalized dining experience with Tripadvisor Filter!
        </div>
      </div>
      {features.map((feature, index) => (
        <div
          key={index}
          className={`feature-section ${
            index % 2 === 0 ? "right-image" : "left-image"
          }`}
        >
          <div className="feature-content container">
            <img
              src={feature.image}
              alt={feature.title}
              className="feature-image"
            />
            <div className="feature-description">
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          </div>
        </div>
      ))}
      <Footer />
    </main>
  );
};

export default TripAdvisorFilterPage;
